.header {
  display: block;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  // Default
  z-index: 5;

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$media-size-phone} {
      flex-direction: row-reverse;
      padding: 15px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    /*margin-left: 5px;*/
    width: $max-width;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .staging {
    font-size: x-small;
    text-align: left;
  }
}

.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
}

.theme-toggler {
  fill: currentColor;
}

.not-selectable {
  user-select: none;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
}

.accent-color {
  display: block;
  height: 5px;
  width: 100%;
  background-color: $accent-color;
}