@charset "UTF-8";

/* Light theme color */
$light-background: #f0f0f0;
$light-background-secondary: #c7c7c7;
$light-background-footer: #ece7e7;
$light-background-header: rgba(240, 240, 240, 0.7);
$light-background-header-mr: rgba(240, 240, 240, 1); /* motion-reduced: no opacity */
$light-color: #222;
$light-color-variant: black;
$light-color-secondary: #999;
$light-border-color: #dcdcdc;
$light-table-color: #dcdcdc;

/* Dark theme colors */
$dark-background: rgba(35, 36, 37, 1);
$dark-background-secondary: rgb(26, 23, 23);
$dark-background-footer: rgba(27, 28, 29, 1);
$dark-background-header: rgba(27, 28, 29, 0.7);
$dark-background-header-mr: rgba(27, 28, 29, 1); /* motion-reduced: no opacity */
$dark-color: #a9a9b3;
$dark-color-variant: white;
$dark-color-secondary: #b3b3bd;
$dark-color-header: #80808a;
$dark-border-color: #4e4e57;
$dark-table-color: #4e4e57;

$accent-color: rgba(160, 176, 255, 1);
$headline-background-color: rgba(160, 176, 255, 1);
$blockquote-background-color: rgba(160, 176, 255, 0.05);

$media-size-phone: "(max-width: 684px)";
$media-size-tablet: "(max-width: 900px)";

/* Variables for js, must be the same as these in @custom-media queries */
:root {
  --phoneWidth: (max-width: 684px);
  --tabletWidth: (max-width: 900px);
}

/* Content */
$max-width: 1060px;