
/* blurry background for menu and submenu */
@mixin blurry-background {
  backdrop-filter: blur(15px);
  content: "";
  display: inline-block;
  z-index: -1;
  
  @media (prefers-color-scheme: dark) {
    background: $dark-background-header;
    //color: $dark-color-header;
  }

  @media (prefers-color-scheme: light) {
    background: $light-background-header;
  }

  [data-theme=dark] & {
    background: $dark-background-header;
  }

  [data-theme=light] & {
    background: $light-background-header;
  }

  @media (prefers-reduced-motion) {
    & {
      backdrop-filter: unset;
    }

    @media (prefers-color-scheme: dark) {
      background: $dark-background-header-mr;
      //color: $dark-color-header;
    }

    @media (prefers-color-scheme: light) {
      background: $light-background-header-mr;
    }

    [data-theme=dark] & {
      background: $dark-background-header-mr;
    }

    [data-theme=light] & {
      background: $light-background-header-mr;
    }
  }
}

%blurry-background {
  @include blurry-background;
}

.menu {
  z-index: 9999;
  position: relative;
  
  // Default
  /*
  background: $light-background-header;

  @media (prefers-color-scheme: dark) {
    background: $dark-background-header;
  }

  @media (prefers-color-scheme: light) {
    background: $light-background-header;
  }

  [data-theme=dark] & {
    background: $dark-background-header;
  }

  [data-theme=light] & {
    background: $light-background-header;
  }
  */

  @media #{$media-size-phone} {
    @include blurry-background;
    position: fixed;
    top: 54px;
    right: 0;
    border: none;
    margin: 0;
    padding: 10px 15px 15px 10px;
    z-index: -100;

    flex-direction: column;
    /* animate slide up/down */
    transform: translateY(-100%);
    transition: transform 0.2s ease;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    margin: 0 auto;
    font-size: 1rem;
    list-style: none;

    @media #{$media-size-phone} {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
  }

  &-trigger {
    width: 24px;
    height: 24px;
    fill: currentColor;
    margin-left: 10px;
    cursor: pointer;
    display: none;

    @media #{$media-size-phone} {
      display: block;
    }
  }

  a {
    display: inline-block;
    text-decoration: none;
    padding: 20px 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

#menu-closer {
  display: none;
}

.menu:target {
  /* show menu */
  transform: translateY(0);
}

.menu:target ~ #menu-trigger {
  display: none;
}

.menu:target ~ #menu-closer {
  display: block;
}

.header:before,
.header .submenu .dropdown-content:before {
  @extend %blurry-background;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.submenu {  
  @media #{$media-size-phone}{
    width: auto;
    padding-top: 5px;
    padding-bottom: 5px;
//    margin-top: 0;
    z-index: 4;
  }

  /*
  background: $light-background-header;

  @media (prefers-color-scheme: dark) {
    background: $dark-background-header;
  }

  @media (prefers-color-scheme: light) {
    background: $light-background-header;
  }

  [data-theme=dark] & {
    background: $dark-background-header;
  }

  [data-theme=light] & {
    background: $light-background-header;
  }
  */
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li a, .dropbtn {
    display: inline-block;
    text-decoration: none;
  }

  li.dropdown {
    cursor: pointer;
    display: inline-block;

    /*
    &:hover {
       opacity: 0.8; 
    }
    */

  }

  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    margin-left: -15px;
  }


  .dropdown-content a {
    text-decoration: none;
    display: block;
    text-align: left;
    padding: 14px;
  }

  .dropdown-content a:hover {
    /*
    background: $dark-background-header;

    &:hover {
      opacity: 0.3;
    }
    */
  }

  .dropdown:hover .dropdown-content {
    display: block;

    @media #{$media-size-phone}{
      display: none;
    }
    
  }
}
