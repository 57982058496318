@mixin logo-dark-theme {
  img {
    display: none;
  }

  span {
    display: inline-block;
  }
}
@mixin logo-light-theme {
  img {
    display: block;
  }

  span {
    display: none;
  }
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  font-display: auto;
  font-family: monospace, monospace;

  img {
    display: block;
    max-height: 44px;
    width: auto;
    height: auto;
  }

  &__mark {
    display: none;
    margin-right: 5px;
  }

  &__text {
    display: none;
    font-size: 1.125rem;
  }

  &__cursor {
    display: none;
    width: 10px;
    height: 1rem;
    background: #fdfdff;
    margin-left: 5px;
    border-radius: 1px;
    animation: cursor 1s infinite;
  }

  @media (prefers-reduced-motion: reduce) {
    &__cursor {
        animation: none;
    }
  }

  @media (prefers-color-scheme: dark){
    @include logo-dark-theme;
  }
  [data-theme=dark] & {
    @include logo-dark-theme;
  }

  @media (prefers-color-scheme: light){
    @include logo-light-theme;
  }
  [data-theme=light] & {
    @include logo-light-theme;
  }

}

@keyframes cursor {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}