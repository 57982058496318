.listingtype-label {
    padding: 4px 8px;
    text-align: center;
    align-self: center;
    border-radius: 7px;
    font-weight: 600;
    font-size: small;
    margin-right: 0.5rem;

    &.status {
        background-color: orangered;
        color: #f9f9f9;
    }
    &.blog {
        background-color: springgreen;
        color: #030303;
    }
    &.other {
        background-color: #21759b;
        color: #030303;
    }
}
  
.posts-list .listingtype-label {
    font-size: x-small;
}

.listingtype-indicator {
    border-radius: 50%;

    /* Alignment */
    display: inline-block;
    justify-content: center;
    align-items: center;

    /* Adjust as required: */
    padding: 15px;

    &.status {
        background-color: orangered;
    }
    &.blog {
        background-color: springgreen;
    }
    &.other {
        background-color: #21759b;
    }
}

.listingtype-indicator::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

/* product badge */
.pb {
    border-radius: 50%;

    --circle-size: 1.5rem;
    --b: initial;
    --d: inline-flex;
    --fz: 0.9rem;
    --fw: normal;
    --h: auto;
    --l: initial;
    --r: initial;
    --t: initial;
    --tt: uppercase;
    --w: initial;
    --p: 0;
	--h: var(--circle-size);
	--pos: relative;
	// --t: -0.75em;
	--tt: initial;
	--w: var(--circle-size);

	align-items: center;
	bottom: var(--b);
	box-sizing: border-box;
	display: var(--d);
	font-size: var(--fz);
	font-weight: var(--fw);
	height: var(--h);
	justify-content: center;
	left: var(--l);
	padding: var(--p);
	position: var(--pos);
	right: var(--r);
	text-decoration: none;
    text-align: center;
	text-transform: var(--tt);
	top: var(--t);
	width: var(--w);

    &.product-s {
        background-color: #8c8aac;
        color: #030303;
    }
    &.product-m {
        background-color: #2bee38;
        color: #030303;
    }
    &.product-l {
        background-color: #ee792b;
        color: #030303;
    }
}